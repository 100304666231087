.landing_footer {
  width: 100%;
  height: 80px;
  background-color: black;
  font-size: 15px;
  color: rgb(131, 131, 131);
  bottom: 0;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 768px) {
  .landing_footer {
    font-size: 12px;
  }
}
