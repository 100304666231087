.functionalities1{
    background-color: rgb(31, 30, 30);
    color: white;
    margin-top: -18px;
    padding: 20px;
    position: fixed;
    top:80px;
    z-index: 10000 !important;
}

.functionalities1.active{
    display: none;
}

.admin-menues{
    display: flex;
    flex-direction: column;
    justify-content: left;


}


.admin-menues>li:hover{
    background-color: blue;
    cursor: pointer;
}

.admin-menues>li a{
    color: white;
    text-decoration: none;
    display:inline-block;
    width:100%;
    padding: 20px;
 
}



.admin-mainwindow{
    margin-top: 60px;
    height: 80vh;
    width: 86%;
    background-color: white;
    float: right;

}

.admin-nav-bar{
    background-color: white;
    height: 60px;
    width: 100%;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
    display: flex;
    align-items: center;
    padding-left: 20px;
    justify-content: flex-end;
    grid-gap: 30px;
    padding-right: 20px;
    /* position: fixed; */
}

.admin-menu-icon{
  display: none;   
}

.avatar{
  cursor: pointer;
  display: block;
}

.dropdown-content1 {
  margin-top: 0px;
  margin-left: -100px;
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 100;
  cursor: pointer;
}

.dropdown-content1 a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}



.avatar:hover .dropdown-content1{
  display: block!important;
}



@media screen and (max-width:1020px)
{
    .admin-mainwindow{
        width: 74%;
    }
}
@media screen and (max-width:768px)
{
    .admin-mainwindow{
        width: 100%;
        margin-top: 40px;
    }
    .admin-menu-icon{
        display: inline-block;   
      }
    .admin-nav-bar{
      position: fixed;
    }
    
}