*{
    box-sizing: border-box;
}
.DashboardHeader{
    height: 80px;
    border-bottom: 1px solid #e2e7ed;
    display: flex;
    align-items: center;
    justify-content: space-around;
    box-shadow: 0 2px 2px 0 rgba(184, 181, 181, 0.2);
    
    
}

/* .DashboardUserDetails{
    margin-left:  auto;
    margin-right: 10px;
} */

.courseContainer{
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: Arial, Helvetica, sans-serif;
    height: 90vh;
    margin: 0px;
    margin-top: -40px;
}

.course{
    background: #FFF;
    border-radius: 4px;
    box-shadow: 0px 5px 40px rgba(0,0,0,0.2);
    display: inline-block;
    overflow: hidden;
}

.course-info{
    background:#2C2464;
    color: #FFF;
    float: left;
    padding: 30px;
    width:40%;
}

.course-progress{
    float: left;
    padding: 30px;
    position: relative;
    width: 60%;
}

.course h2{
    letter-spacing: 1px;
    margin: 10px 0px;
}

.course h6{
    letter-spacing: 1px;
    opacity: 0.8;
    text-transform: uppercase;
    margin: 0px;
}

.course-info a{
    color: #FFF;
    display: inline-block;
    font-size: 12px;
    opacity: 0.8;
    margin-top: 30px;
    text-decoration: none;
}

.progress-container{
    position: absolute;
    top: 30px;
    right: 30px;
    text-align: right;
    width: 150px;
}

.progress{
    background: #DDD;
    border-radius: 3px;
    height: 5px;
    width: 100%;
}

.progress >span{
    border-radius: 3px;
    background: #2C2464;
    content: '';
    position: absolute;
    top: 0px;
    left: 0px;
    height: 5px;
}

.progress-text{
    padding-top: 10px;
    font-size: 10px;
    opacity: 0.8;
    letter-spacing: 1px;
}

.btn{
    background: #2C2464;
    border: 0px;
    outline: none;
    border-radius: 2px;
    /* box-shadow: 0px 10px 10px rgba(0,0,0,0.2); */
    color: #FFF;
    font-size: 16px;
    padding: 12px 25px;
    position: absolute;
    right: 30px;
    bottom: -28px !important;
    cursor: pointer;
    letter-spacing: 1px;
}

.dropdown-content {
    margin-top: 10px;
    margin-left: -50px;
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
    cursor: pointer;
  }
  
  .dropdown-content a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
  }

  .DashboardUserDetails{
      cursor: pointer;
      margin-top: 2px;
  }

  .DashboardUserDetails:hover .dropdown-content {
    display: block;
  }


@media screen and (max-width: 768px)
{

    .headerText{
        display: none !important;
    }
    .courseContainer{
        margin-left: 10px;
        margin-right: 10px;
    }
    .course-progress, .course-info{
        width: 100%;
        height: 200px;
        float: none;
    }
    .course-info{
        height: 140px;
    }
    .course-info h2{
        margin: 8px 0px;
    }
    .course-info a{
        margin-top: 10px;
    }
    .course-progress h2{
        margin: 16px 0px;
    }
    .btn{
        bottom: 10px !important;
        left: 30px;
    }
}