.singup-logo {
  /* width: 200px; */
  height: 100px;
  /* background-color: rgb(0, 238, 127); */
  margin: 20px;
}

.singup-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 500px;
  margin: auto;
  padding: 20px;
}

form {
  width: 100%;
}

.singup-container > form {
  display: flex;
  flex-direction: column;
  margin: 50px;
}

.singup-container > form > input {
  padding: 10px 0 10px 15px;
  font-size: 17px;
  font-weight: 300;
  color: rgb(24, 23, 23);
  letter-spacing: 1px;
  border: none;
  background: transparent;
  font-family: 'Mukta', sans-serif;
  width: 100%;
  outline: none;
  border: 1px solid rgb(216, 60, 255);
  margin-top: 10px;
}

.submit-btn {
  padding: 10px 0 10px 15px;
  font-size: 17px;
  font-weight: 300;
  color: rgb(216, 60, 255);
  letter-spacing: 1px;
  border: none;
  background: transparent;
  font-family: 'Mukta', sans-serif;
  width: 100%;
  outline: none;
  border: 1px solid rgb(216, 60, 255);
  margin-top: 10px;
  cursor: pointer;
}

.submit-btn:hover {
  background: whitesmoke;
}

.error {
  color: red;
}

#research-field, #occupation{
  padding: 10px 0 10px 15px;
  font-size: 17px;
  font-weight: 300;
  color: rgb(24, 23, 23);
  letter-spacing: 1px;
  border: none;
  background: transparent;
  font-family: 'Mukta', sans-serif;
  width: 100%;
  outline: none;
  border: 1px solid rgb(216, 60, 255);
  margin-top: 10px;
  cursor: pointer;
}
