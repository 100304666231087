.question-answer-container {
  display: grid;
  justify-content: center;
  align-content: center;
  border-radius: 1px;
  background-image: radial-gradient(whitesmoke, whitesmoke);
  font-size: 1rem;
  padding: 2px;
  padding-bottom: 5px;
  margin-bottom: 10px;
}

.submit-btn-quiz {
  background-color: black;
  color: white;
  padding: 5px;
  border-radius: 55px;
  text-transform: capitalize;
}

.question {
  display: inline-block;
  background: rgb(58, 57, 57);
  color: white;
  padding: 15px;
}

.question-number {
  display: inline-block;
  height: 20px;
  width: 20px;
  background: white;
  color: black;
  border-radius: 100%;
  margin: 0 5px 0 0;
  text-align: center;
}

.answer-container {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-template-rows: repeat(4, 1fr);
  row-gap: 20px;
  /* grid-column-gap: 50px; */
  margin: 10px 20px;
}

.answer {
  display: inline-block;
  font-size: 20px;
  border-radius: 5px;
  text-align: start;
  padding: 10px;
  color: rgb(255, 255, 255);
  background-color: rgb(3, 67, 163);
}

.black-border {
  border: 5px solid rgb(0, 0, 0);
}

.red-border {
  border: 5px solid rgb(211, 0, 0);
}

.green-border {
  border: 5px solid rgb(64, 216, 64);
}

.next-btn {
  height: fit-content;
  width: fit-content;
  justify-self: end;
  margin: 10px;
  padding: 10px;
  background: rgb(58, 57, 57);
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.hidden {
  display: none;
}

@media screen and (max-width: 768px) {
  .question-answer-container {
    margin: 0 auto;
    font-size: .8rem;
  }
  .answer{
    font-size: 13px !important;
  }
}
