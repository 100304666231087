.admin-nav-bar{
    background-color: white;
    height: 60px;
    width: 100%;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
    display: flex;
    align-items: center;
    padding-left: 20px;
    justify-content: flex-end;
    grid-gap: 30px;
    padding-right: 20px;
    /* position: fixed; */
}

.admin-menu-icon{
  display: none;   
}

.avatar{
  cursor: pointer;
  display: block;
}

.dropdown-content1 {
  margin-top: 0px;
  margin-left: -100px;
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 100;
  cursor: pointer;
}

.dropdown-content1 a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}



.avatar:hover .dropdown-content1{
  display: block!important;
}


@media screen and (max-width:768px)
{
    .admin-menu-icon{
        display: inline-block;   
      }
    .admin-nav-bar{
      position: fixed;
    }
}