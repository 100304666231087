.review_assignment{
    margin: 20px;
    margin-top: 10px;
}
input[type='text'] {
    margin: 10px 10px 10px 0px;
    padding: 7px;
    width: 100%;
  }
textarea{
    margin: 10px 10px 10px 0px;
    width: 100%;
    padding: 5px;
}
input[type="submit"]
{
    margin: 10px 10px 10px 0px;
    padding: 8px;
    width: 300px;
    color: white;
    background-color: blueviolet;
    border-style: none;
    box-shadow: 0 2px 4px 0 rgba(0.2, 0.2, 0.2, 0.5);
    border-radius: 5px;
}

.pdf-viewer
{
    height: 350px;
}

.assignment-name{
    display: inline;
    text-align: center;

}

@media screen and (max-width: 768px)
{
    .pdf-viewer{
        height: 300px;
    }
    .assignment-name{
        font-size: 13px;
    }
}

