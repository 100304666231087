/* input[type='email'],
input[type='text'],
input[type='number'],
input[type='password'],
textarea,
select {
  width: 400px;
}

label {
  margin-top: 1rem;
}

button[type='submit'] {
  margin-top: 1rem;
} */

/* * {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-tap-highlight-color: transparent;
} */
/* 
html {
  font-size: 16px;
  font-family: -apple-system, BlinkMacSystemFont, "avenir next", avenir,
    "helvetica neue", helvetica, ubuntu, roboto, noto, "segoe ui", arial,
    sans-serif;
  margin: 0;
  padding: 0;
  text-decoration-skip-ink: "auto";
} */

/* :root {
  --gray-900: #1a202c;
  --gray-800: #2d3748;
  --gray-700: #4a5568;
  --gray-600: #718096;
  --gray-500: #a0aec0;
  --gray-400: #cbd5e0;
  --gray-300: #e2e8f0;
  --gray-200: #edf2f7;
  --gray-100: #f7fafc;
  --red-100: #fff5f5;
  --red-200: #fed7d7;
  --red-300: #feb2b2;
  --red-400: #fc8181;
  --red-500: #f56565;
  --red-600: #e53e3e;
  --red-700: #c53030;
  --red-800: #9b2c2c;
  --red-900: #742a2a;
  --blue-100: #ebf8ff;
  --blue-200: #bee3f8;
  --blue-300: #90cdf4;
  --blue-400: #63b3ed;
  --blue-500: #4299e1;
  --blue-600: #3182ce;
  --blue-700: #2b6cb0;
  --blue-800: #2c5282;
  --blue-900: #2a4365;
  --white: white;
  --red: #f56565;
  --text-decoration-color: var(--gray-400);
  --text-color: var(--gray-800);
  --focus-ring-color: var(--blue-500);
}

label {
  font-weight: bold;
  display: flex;
}

input[type='email'],
input[type='text'],
input[type='number'],
input[type='password'],
textarea {
  padding: 0.65rem 0.5rem;
  font-size: 1rem;
  border: 2px solid var(--gray-200);
  background-color: var(--gray-100);
  color: var(--gray-800);
  border-radius: 10px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

@media (prefers-color-scheme: dark) {
  input[type='email'],
  input[type='text'],
  input[type='number'],
  input[type='password'],
  input[type='submit'] {
    background-color: var(--gray-800);
    border-color: var(--gray-700);
    color: white;
  }

  button {
    background-color: var(--gray-800);
    border-color: var(--gray-700);
    color: white;
  }

  input[type='email']::placeholder,
  input[type='text']::placeholder,
  input[type='number']::placeholder,
  select,
  textarea {
    color: var(--gray-500);
  }
}

input[type='email'],
input[type='text'],
input[type='number'],
input[type='password'],
textarea,
select {
  width: 350px;
}

select {
  margin: 0;
  -webkit-appearance: none;
  box-sizing: border-box;
  padding: 0.65rem 0.5rem;
  font-size: 1rem;
  border: 2px solid var(--gray-200);
  border-radius: 10px;
  color: var(--gray-700);
  height: auto;
  background-color: var(--gray-100);
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 125.304 125.304"><path d="M62.652 103.895L0 21.41h125.304" fill="%23343334"/></svg>');
  background-repeat: no-repeat;
  background-size: 1rem;
  background-position: center right 0.5rem;
}

@media (prefers-color-scheme: dark) {
  select {
    background-color: var(--gray-800);
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 125.304 125.304"><path d="M62.652 103.895L0 21.41h125.304" fill="%23ffffff"/></svg>');
    border-color: var(--gray-700);
    color: white;
  }
}

@media (prefers-color-scheme: dark) {
  textarea {
    background-color: var(--gray-800);
    border-color: var(--gray-700);
    color: white;
  }
}

input[type='submit'],
input[type='reset'],
input[type='button'],
button {
  padding: 0.5rem 1.25rem;
  font-size: 1rem;
  border-radius: 10px;
  background-color: var(--gray-700);
  border: 2px solid var(--gray-700);
  color: white;
  text-decoration: none;
  font-weight: bold;
  margin-bottom: 1rem;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  display: inline-block;
  line-height: initial;
  transition: background-color 200ms ease-in-out, border 200ms ease-in-out,
    transform 200ms ease-in-out;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  user-select: none;
}

@media (prefers-color-scheme: dark) {
  input[type='submit'],
  input[type='reset'],
  input[type='button'],
  button {
    background-color: white;
    border: 2px solid white;
    color: var(--gray-800);
  }
}

button:hover {
  cursor: pointer;
} */
/* button:focus-visible,
input[type="submit"]:focus-visible,
input[type="reset"]:focus-visible,
input[type="button"]:focus-visible {
  border-color: var(--focus-ring-color);
  outline: none;
} */

.error {
  font-size: 12px;
  color: var(--red-600);
  margin-top: 0.25rem;
}

/* mine */

.mobile-view-form-top-div {
  display: none;
}
.social-media-mobile-view {
  display: none;
}
.container {
  display: flex;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  position: relative;
  overflow: hidden;
  width: 768px;
  margin: 100px auto;
  max-width: 100%;
  min-height: 480px;
}

.overlay-container {
  position: absolute;
  top: 0;
  left: 50%;
  width: 50%;
  height: 100%;
  overflow: hidden;
  transition: transform 0.6s ease-in-out;
  z-index: 100;
}

.overlay {
  background: #ff416c;
  background: linear-gradient(to right, rgb(255, 234, 190), rgb(184, 214, 214))
    no-repeat 0 0 / cover;
  color: rgb(0, 0, 0);
  position: relative;
  left: -100%;
  height: 100%;
  width: 200%;
  transform: translateY(0);
  transition: transform 0.6s ease-in-out;
}

.overlay-panel {
  position: absolute;
  top: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 40px;
  height: 100%;
  width: 50%;
  text-align: center;
  transform: translateY(0);
  transition: transform 0.6s ease-in-out;
}

.overlay-right {
  right: 0;
  transform: translateY(0);
}

.overlay-left {
  transform: translateY(-20%);
}

.overlay-right *,
.overlay-left * {
  margin: 3px;
}

/* .container form {
  margin-left: 10px;
} */

/* .student-signup-container {
  left: 0;
  width: 50%;
  z-index: 2;
  margin-left: 10px;
} */

/* .supervisor-signup-container {
  left: 0;
  width: 50%;
  z-index: 1;
  opacity: 0;
} */

/* Move form to right */
.container.right-panel-active .student-signup-container {
  transform: translateY(100%);
}

/* Move overlay to left */
.container.right-panel-active .overlay-container {
  transform: translateX(-100%);
}

/* Bring studentsingup over supervisor */
.container.right-panel-active .superisor-signup-container {
  transform: translateX(100%);
  opacity: 1;
  z-index: 5;
}

/* Move overlay back to right */
.container.right-panel-active .overlay {
  transform: translateX(50%);
}

/* Bring back the text to center */
.container.right-panel-active .overlay-left {
  transform: translateY(0);
}

/* Same effect for right */
.container.right-panel-active .overlay-right {
  transform: translateY(20%);
}

/* --------------- */

@media screen and (max-width: 800px) {
  .container {
    height: 100vh;
    flex-direction: column;
    overflow-y: scroll;
    margin-top: 0;
  }

  .mobile-view-form-top-div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
    background-color: rgb(184, 214, 214);
  }

  .social-media-mobile-view {
    display: flex;
    flex-direction: column;
    /* position: sticky; */
    top: 50%;
    margin: 8px;
    width: fit-content;
  }

  .social-media-mobile-view * {
    /* padding: 3px; */
    margin-top: 5px;
  }

  .overlay-container {
    position: absolute;
    top: 0;
    left: 0%;
    width: 100%;
    height: 100%;
    overflow: hidden;
    transition: transform 0.6s ease-in-out;
    z-index: 100;
  }

  .overlay {
    display: flex;
    flex-direction: column;
    background: #ff416c;
    background: linear-gradient(
        to right,
        rgb(255, 234, 190),
        rgb(184, 214, 214)
      )
      no-repeat 0 0 / cover;
    color: rgb(0, 0, 0);
    position: relative;
    left: 0%;
    height: 100%;
    width: 100%;
    right: 0;
    transform: translateY(0);
    transition: transform 0.6s ease-in-out;
  }

  .overlay-panel {
    position: static;
    /* top: 0; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 40px;
    height: 100%;
    width: 100%;
    text-align: center;
    transform: translateY(0);
    transition: transform 0.6s ease-in-out;
  }

  .overlay-right {
    right: 0;
    transform: translateY(10%);
  }

  .overlay-left {
    transform: translateY(0%);
  }

  .superisor-signup-container {
    display: none;
  }
  .student-signup-container {
    display: none;
  }

  /* Bring studentsingup over supervisor */
  .container.right-panel-active .superisor-signup-container {
    transform: translateX(100%);
    opacity: 1;
    z-index: 5;
  }

  /* Move overlay back to right */
  .container.right-panel-active .overlay-container {
    display: none;
  }
  .container.right-panel-active .student-signup-container {
    display: none;
  }

  .container.student-signup-active .overlay-container {
    display: none;
  }
  .container.student-signup-active .supervisor-signup-container {
    display: none;
  }
  .container.student-signup-active .student-signup-container {
    display: flex;
  }
  .container.student-signup-active .supervisor-signup-container {
    display: none;
  }
}

button:disabled {
  cursor: not-allowed;
}

.loading:after {
  content: ' .';
  animation: dots 1s steps(5, end) infinite;
}

@keyframes dots {
  0%,
  20% {
    color: rgba(0, 0, 0, 0);
    text-shadow: 0.25em 0 0 rgba(0, 0, 0, 0);
  }
  40% {
    color: white;
    text-shadow: 0.25em 0 0 rgba(0, 0, 0, 0);
  }
  60% {
    text-shadow: 0.25em 0 0 white;
  }
  80%,
  100% {
    text-shadow: 0.25em 0 0 white, 0.5em 0 0 white;
  }
}
