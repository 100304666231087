.create-course{

    height: 500px;
    display: flex;
    flex-direction: column;
    margin: 20px;
    margin-top: 10px;

}

.heading_name
{
    background-color: blueviolet;
    color: white;
    width: 100%;
    border-radius: 5px;
    padding: 6px;
    font-size: 18px;
}

input[type='text'] {
    margin: 10px 10px 10px 0px;
    padding: 7px;
    width: 100%;
  }
textarea{
    margin: 10px 10px 10px 0px;
    width: 100%;
    padding: 5px;
}
input[type="submit"]
{
    margin: 10px 10px 10px 0px;
    padding: 8px;
    width: 300px;
    color: white;
    background-color: blueviolet;
    border-style: none;
    box-shadow: 0 2px 4px 0 rgba(0.2, 0.2, 0.2, 0.5);
    border-radius: 5px;
}

.course-curriculumn{
    margin-top: 10px;
    border: 1px solid black;
    width: 100%;
    height: 380px;
    overflow-y: scroll;
}
.unit-section{
    margin: 8px;
    background-color: #e6e8eb;
    padding: 8px;
    box-shadow: 0 2px 4px 0 rgba(0.2, 0.2, 0.2, 0.5);
}

.sections{
    margin: 8px;
    background-color: white;
    padding: 8px;
}

.questions{
    margin: 8px;
    background-color: rgb(48, 107, 185);
    padding: 8px;
    color: white;
}
.create-unit-button{
    background-color: rgb(48, 185, 117);
    color: white;
    border-style: none;
    padding: 8px;
    margin: 8px;
    font-weight: bold;

}

.create-course-button{
    font-weight: bold;
    
}

