.ImageSlider {
  width: 70%;
  height: 72vh;
  margin: 0 auto;
  margin-top: 80px;
}

.SliderImages {
  height: 100%;
  width: 100%;
  transition-duration: 0.5s;
  opacity: 0;
  transition-duration: 0.5;
  opacity: 1;
}

@media screen and (max-width: 960px) {
  .ImageSlider {
    height: 450px;
  }
}

@media screen and (max-width: 768px) {
  .ImageSlider {
    height: 300px;
    width: 100%;
  }
}
