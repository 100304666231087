:root {
  --border-color: teal;
}

.contact-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 500px;
  margin: auto;
  padding: 20px;
  height: 80vh;
  margin-bottom: 20px;
}

.contact-form {
  display: flex;
  flex-direction: column;
}

.header-text {
  letter-spacing: 6px;
  font-size: 30px;
}

.text-align-center {
  text-align: center;
}
.contact-container > form {
  display: flex;
  flex-direction: column;
  margin: 30px;
}

.contact-container > form > input,
.contact-container > form > textarea {
  padding: 8px ;
  font-size: 14px;
  font-weight: 300;
  color: rgb(24, 23, 23);
  letter-spacing: 1px;
  border: none;
  background: transparent;
  font-family: 'Mukta', sans-serif;
  width: 100%;
  outline: none;
  border: 1px solid var(--border-color);
  margin-top: 10px;
}

.send-button {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 8px;
  font-size: 14px;
  font-weight: 300;
  padding: 8px;
  border: 1px solid var(--border-color);
  color: var(--border-color);
  cursor: pointer;
}

.send-button:hover {
  transform: translate3d(0px, -5px, 0px);
}
/*social media section */
.social-media-icon-container {
}

hr {
  color: var(--border-color);
}

.social-media-icon-list {
  list-style-type: none;
  display: flex;
  justify-content: center;
  position: relative;
  font-size: 2rem;
  text-align: center;
  width: 100%;
}

.social-media-icon-list li a {
  color: white;
}

.social-media-icon-list li {
  position: relative;
  top: 0;
  /* left: -20px; */
  display: inline-block;
  height: 60px;
  width: 60px;
  margin: 8px auto;
  line-height: 60px;
  border-radius: 50%;
  color: #fff;
  background-color: var(--border-color);
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

.social-media-icon-list li:hover {
  color: black;
}

.list-item {
  line-height: 4;
  color: rgb(112, 103, 103);
}

.Toastify button {
  display: none;
}

@media screen and (max-width: 768px)
{
  .contact{
    width: 100%;
  }
  .contact-container{
    margin-bottom: 60px;
  }
}


