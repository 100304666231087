@import url("https://fonts.googleapis.com/css2?family=Lato:wgh@300;400;700&display=swap");

/* Scrollbars */

::-webkit-scrollbar {
  width: 5px;
  height: 6px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #a5aaad;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: #3ea175;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: #a5aaad;
}

*{
  margin: 0;
  padding: 0;
}

body{
  box-sizing: border-box;
  font-family: "Lato",sans-serif;
}


.container{
  display: grid;
  height: 100vh;
  grid-template-columns: .8fr 1fr 1fr 1fr;
  grid-template-rows: .2fr 3fr;
  grid-template-areas: 
  "sidebar nav nav nav"
  "sidebar main main main";
}

@media only screen and (max-width: 978px)
{
  .container{
    grid-template-columns: 1fr;
    grid-template-rows: .2fr 3fr;
    grid-template-areas: 
    "nav"
    "main";
  }
}

body {
  /* overflow-y: scroll; */
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    "Open Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
