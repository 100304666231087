.create-news{
    margin: 20px;
    margin-top: 10px;
}

.post-button{
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 10px 10px 10px 0px;
    padding: 8px;
    width: 300px;
    color: white;
    background-color: blueviolet;
    border-style: none;
    box-shadow: 0 2px 4px 0 rgba(0.2, 0.2, 0.2, 0.5);
    border-radius: 5px;
    outline-style: none;
    font-weight: bold;
}

.news-details{
    border: 1px solid black;
    height: 400px;
    padding: 5px;   
    overflow-y: auto;
}


select{
    margin: 10px 10px 10px 0px;
    padding: 7px;
    width: 100%;
}

input[type='text'] {
    margin: 10px 10px 10px 0px;
    padding: 7px;
    width: 100%;
  }
textarea{
    margin: 10px 10px 10px 0px;
    width: 100%;
    padding: 5px;
}
input[type="submit"]
{
    margin: 10px 10px 10px 0px;
    padding: 8px;
    width: 300px;
    color: white;
    background-color: blueviolet;
    border-style: none;
    box-shadow: 0 2px 4px 0 rgba(0.2, 0.2, 0.2, 0.5);
    border-radius: 5px;
}


.heading_name
{
    background-color: blueviolet;
    color: white;
    width: 100%;
    border-radius: 5px;
    padding: 6px;
    font-size: 18px;
}

