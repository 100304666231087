@import url(https://fonts.googleapis.com/css2?family=Lato:wgh@300;400;700&display=swap);
/* Scrollbars */

::-webkit-scrollbar {
  width: 5px;
  height: 6px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #a5aaad;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: #3ea175;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: #a5aaad;
}

*{
  margin: 0;
  padding: 0;
}

body{
  box-sizing: border-box;
  font-family: "Lato",sans-serif;
}


.container{
  display: grid;
  height: 100vh;
  grid-template-columns: .8fr 1fr 1fr 1fr;
  grid-template-rows: .2fr 3fr;
  grid-template-areas: 
  "sidebar nav nav nav"
  "sidebar main main main";
}

@media only screen and (max-width: 978px)
{
  .container{
    grid-template-columns: 1fr;
    grid-template-rows: .2fr 3fr;
    grid-template-areas: 
    "nav"
    "main";
  }
}

body {
  /* overflow-y: scroll; */
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    "Open Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}



* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  font-family: 'Lato', sans-serif;
}

.NavbarItems {
  background-color: white;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  z-index: 1030;
}

.navbar-logo {
  justify-self: start;
  cursor: pointer;
  margin-left: 0px;
}

.nav-menu {
  display: grid;
  grid-template-columns: repeat(9, auto);
  grid-gap: 10px;
  list-style: none;
  text-align: center;
  width: 70vw;
  justify-content: end;
  align-items: center;
  margin-right: 2rem;
  cursor: pointer;
}

.nav-links {
  cursor: pointer;
  color: #222222;
  font-size: 15px;
  text-decoration: none;
  padding: 0.1rem 1rem;
}

.nav-links:hover {
  background-color: #6d76f7;
  color: white;
  border-radius: 4px;
  transition: all 0.2 ease-out;
}

.menu-icon {
  display: none;
}

.nav-login-button {
  /* background-color: #14a79f; */
  border: 2px solid #2896a9;
  color:#0f7c90;
  cursor: pointer;
  font-weight: bold;
  padding: 10px;
}
.nav-signup-button{
  border: 2px solid #2896a9;
  background-color:#2896a9;
  color:white;
  cursor: pointer;
  font-weight: bold;
  padding: 10px;

}
.nav-login-button:hover {
  background-color: transparent;
  color: #0a5e6d;
  border-radius: 0px;
}
.nav-signup-button:hover {
  background-color: #0a5e6d;
  border-radius: 0px;
  border-color: #0a5e6d;
}

ul li ul {
  visibility: hidden;
  opacity: 0;
  position: absolute;
  transition: all 0.5s ease;
  margin-top: 1rem;
  left: 0;
  display: none;
}

.dropdown {
  display: block;
}

.dropdown-content {
  margin-top: 0px!important;
  margin-left: -50px;
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
  cursor: pointer;
}

.dropdown-content > ul > li > a {
  cursor: pointer;
}

.dropdown-content > ul > li > a:hover {
  background-color: #6d76f7;
  color: white;
  border-radius: 4px;
  transition: all 0.2 ease-out;
}

.dropdown:hover .dropdown-content {
  display: block;
}

@media screen and (max-width: 960px) {
  .nav-button {
    background-color: none;
    width: 50% !important;
    margin: 0 auto !important;
  }
  .NavbarItems {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    z-index: 1030;
  }

  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 800px;
    position: absolute;
    top: 80px;
    left: -100%;
    opacity: 1;
    transition: all 0.5 ease;
  }
  .nav-menu.active {
    background-color: #131313;
    left: 0;
    opacity: 1;
    transition: all 0.5 ease;
    z-index: 1;
  }
  .nav-links {
    color: white;
    text-align: center;
    padding: 1.5rem;
    width: 100%;
    display: table;
  }
  .nav-links:hover {
    background-color: #7577fa;
    border-radius: 0;
  }
  .navbar-logo {
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(25%, 0%);
  }
  .menu-icon {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
  }
}

@media screen and (max-width: 768px) {
  .nav-links {
    padding: 1rem;
  }
}

.ImageSlider {
  width: 70%;
  height: 72vh;
  margin: 0 auto;
  margin-top: 80px;
}

.SliderImages {
  height: 100%;
  width: 100%;
  transition-duration: 0.5s;
  opacity: 0;
  transition-duration: 0.5;
  opacity: 1;
}

@media screen and (max-width: 960px) {
  .ImageSlider {
    height: 450px;
  }
}

@media screen and (max-width: 768px) {
  .ImageSlider {
    height: 300px;
    width: 100%;
  }
}

.width-seventy {
  width: 70%;
}
.center {
  margin: 20px auto;
}

.text-container {
  color: #333333;
  font-family: Tahoma, Geneva, Verdana, sans-serif;
  font-weight: 400;
  font-size: 16px;
  margin: 10px 0;
}

ul {
  list-style-type: none;
}

.text-align-center {
  text-align: center;
  color: #333333;
  font-size: 18px;
  text-align: left;
  font-weight: 600;
}

.heading{
  color: #333333;
  font-size: 20px;
}

@media screen and (max-width: 768px) {
  .width-seventy {
    width: 100%;
  }
  .text-container {
    padding-left: 5px;
    font-weight: 300;
  }
  .text-align-center {
    padding-left: 5px;
    font-weight: 400;
  }
}

/* input[type='email'],
input[type='text'],
input[type='number'],
input[type='password'],
textarea,
select {
  width: 400px;
}

label {
  margin-top: 1rem;
}

button[type='submit'] {
  margin-top: 1rem;
} */

/* * {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-tap-highlight-color: transparent;
} */
/* 
html {
  font-size: 16px;
  font-family: -apple-system, BlinkMacSystemFont, "avenir next", avenir,
    "helvetica neue", helvetica, ubuntu, roboto, noto, "segoe ui", arial,
    sans-serif;
  margin: 0;
  padding: 0;
  text-decoration-skip-ink: "auto";
} */

/* :root {
  --gray-900: #1a202c;
  --gray-800: #2d3748;
  --gray-700: #4a5568;
  --gray-600: #718096;
  --gray-500: #a0aec0;
  --gray-400: #cbd5e0;
  --gray-300: #e2e8f0;
  --gray-200: #edf2f7;
  --gray-100: #f7fafc;
  --red-100: #fff5f5;
  --red-200: #fed7d7;
  --red-300: #feb2b2;
  --red-400: #fc8181;
  --red-500: #f56565;
  --red-600: #e53e3e;
  --red-700: #c53030;
  --red-800: #9b2c2c;
  --red-900: #742a2a;
  --blue-100: #ebf8ff;
  --blue-200: #bee3f8;
  --blue-300: #90cdf4;
  --blue-400: #63b3ed;
  --blue-500: #4299e1;
  --blue-600: #3182ce;
  --blue-700: #2b6cb0;
  --blue-800: #2c5282;
  --blue-900: #2a4365;
  --white: white;
  --red: #f56565;
  --text-decoration-color: var(--gray-400);
  --text-color: var(--gray-800);
  --focus-ring-color: var(--blue-500);
}

label {
  font-weight: bold;
  display: flex;
}

input[type='email'],
input[type='text'],
input[type='number'],
input[type='password'],
textarea {
  padding: 0.65rem 0.5rem;
  font-size: 1rem;
  border: 2px solid var(--gray-200);
  background-color: var(--gray-100);
  color: var(--gray-800);
  border-radius: 10px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

@media (prefers-color-scheme: dark) {
  input[type='email'],
  input[type='text'],
  input[type='number'],
  input[type='password'],
  input[type='submit'] {
    background-color: var(--gray-800);
    border-color: var(--gray-700);
    color: white;
  }

  button {
    background-color: var(--gray-800);
    border-color: var(--gray-700);
    color: white;
  }

  input[type='email']::placeholder,
  input[type='text']::placeholder,
  input[type='number']::placeholder,
  select,
  textarea {
    color: var(--gray-500);
  }
}

input[type='email'],
input[type='text'],
input[type='number'],
input[type='password'],
textarea,
select {
  width: 350px;
}

select {
  margin: 0;
  -webkit-appearance: none;
  box-sizing: border-box;
  padding: 0.65rem 0.5rem;
  font-size: 1rem;
  border: 2px solid var(--gray-200);
  border-radius: 10px;
  color: var(--gray-700);
  height: auto;
  background-color: var(--gray-100);
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 125.304 125.304"><path d="M62.652 103.895L0 21.41h125.304" fill="%23343334"/></svg>');
  background-repeat: no-repeat;
  background-size: 1rem;
  background-position: center right 0.5rem;
}

@media (prefers-color-scheme: dark) {
  select {
    background-color: var(--gray-800);
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 125.304 125.304"><path d="M62.652 103.895L0 21.41h125.304" fill="%23ffffff"/></svg>');
    border-color: var(--gray-700);
    color: white;
  }
}

@media (prefers-color-scheme: dark) {
  textarea {
    background-color: var(--gray-800);
    border-color: var(--gray-700);
    color: white;
  }
}

input[type='submit'],
input[type='reset'],
input[type='button'],
button {
  padding: 0.5rem 1.25rem;
  font-size: 1rem;
  border-radius: 10px;
  background-color: var(--gray-700);
  border: 2px solid var(--gray-700);
  color: white;
  text-decoration: none;
  font-weight: bold;
  margin-bottom: 1rem;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  display: inline-block;
  line-height: initial;
  transition: background-color 200ms ease-in-out, border 200ms ease-in-out,
    transform 200ms ease-in-out;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  user-select: none;
}

@media (prefers-color-scheme: dark) {
  input[type='submit'],
  input[type='reset'],
  input[type='button'],
  button {
    background-color: white;
    border: 2px solid white;
    color: var(--gray-800);
  }
}

button:hover {
  cursor: pointer;
} */
/* button:focus-visible,
input[type="submit"]:focus-visible,
input[type="reset"]:focus-visible,
input[type="button"]:focus-visible {
  border-color: var(--focus-ring-color);
  outline: none;
} */

.error {
  font-size: 12px;
  color: var(--red-600);
  margin-top: 0.25rem;
}

/* mine */

.mobile-view-form-top-div {
  display: none;
}
.social-media-mobile-view {
  display: none;
}
.container {
  display: flex;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  position: relative;
  overflow: hidden;
  width: 768px;
  margin: 100px auto;
  max-width: 100%;
  min-height: 480px;
}

.overlay-container {
  position: absolute;
  top: 0;
  left: 50%;
  width: 50%;
  height: 100%;
  overflow: hidden;
  transition: transform 0.6s ease-in-out;
  z-index: 100;
}

.overlay {
  background: #ff416c;
  background: linear-gradient(to right, rgb(255, 234, 190), rgb(184, 214, 214))
    no-repeat 0 0 / cover;
  color: rgb(0, 0, 0);
  position: relative;
  left: -100%;
  height: 100%;
  width: 200%;
  transform: translateY(0);
  transition: transform 0.6s ease-in-out;
}

.overlay-panel {
  position: absolute;
  top: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 40px;
  height: 100%;
  width: 50%;
  text-align: center;
  transform: translateY(0);
  transition: transform 0.6s ease-in-out;
}

.overlay-right {
  right: 0;
  transform: translateY(0);
}

.overlay-left {
  transform: translateY(-20%);
}

.overlay-right *,
.overlay-left * {
  margin: 3px;
}

/* .container form {
  margin-left: 10px;
} */

/* .student-signup-container {
  left: 0;
  width: 50%;
  z-index: 2;
  margin-left: 10px;
} */

/* .supervisor-signup-container {
  left: 0;
  width: 50%;
  z-index: 1;
  opacity: 0;
} */

/* Move form to right */
.container.right-panel-active .student-signup-container {
  transform: translateY(100%);
}

/* Move overlay to left */
.container.right-panel-active .overlay-container {
  transform: translateX(-100%);
}

/* Bring studentsingup over supervisor */
.container.right-panel-active .superisor-signup-container {
  transform: translateX(100%);
  opacity: 1;
  z-index: 5;
}

/* Move overlay back to right */
.container.right-panel-active .overlay {
  transform: translateX(50%);
}

/* Bring back the text to center */
.container.right-panel-active .overlay-left {
  transform: translateY(0);
}

/* Same effect for right */
.container.right-panel-active .overlay-right {
  transform: translateY(20%);
}

/* --------------- */

@media screen and (max-width: 800px) {
  .container {
    height: 100vh;
    flex-direction: column;
    overflow-y: scroll;
    margin-top: 0;
  }

  .mobile-view-form-top-div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
    background-color: rgb(184, 214, 214);
  }

  .social-media-mobile-view {
    display: flex;
    flex-direction: column;
    /* position: sticky; */
    top: 50%;
    margin: 8px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
  }

  .social-media-mobile-view * {
    /* padding: 3px; */
    margin-top: 5px;
  }

  .overlay-container {
    position: absolute;
    top: 0;
    left: 0%;
    width: 100%;
    height: 100%;
    overflow: hidden;
    transition: transform 0.6s ease-in-out;
    z-index: 100;
  }

  .overlay {
    display: flex;
    flex-direction: column;
    background: #ff416c;
    background: linear-gradient(
        to right,
        rgb(255, 234, 190),
        rgb(184, 214, 214)
      )
      no-repeat 0 0 / cover;
    color: rgb(0, 0, 0);
    position: relative;
    left: 0%;
    height: 100%;
    width: 100%;
    right: 0;
    transform: translateY(0);
    transition: transform 0.6s ease-in-out;
  }

  .overlay-panel {
    position: static;
    /* top: 0; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 40px;
    height: 100%;
    width: 100%;
    text-align: center;
    transform: translateY(0);
    transition: transform 0.6s ease-in-out;
  }

  .overlay-right {
    right: 0;
    transform: translateY(10%);
  }

  .overlay-left {
    transform: translateY(0%);
  }

  .superisor-signup-container {
    display: none;
  }
  .student-signup-container {
    display: none;
  }

  /* Bring studentsingup over supervisor */
  .container.right-panel-active .superisor-signup-container {
    transform: translateX(100%);
    opacity: 1;
    z-index: 5;
  }

  /* Move overlay back to right */
  .container.right-panel-active .overlay-container {
    display: none;
  }
  .container.right-panel-active .student-signup-container {
    display: none;
  }

  .container.student-signup-active .overlay-container {
    display: none;
  }
  .container.student-signup-active .supervisor-signup-container {
    display: none;
  }
  .container.student-signup-active .student-signup-container {
    display: flex;
  }
  .container.student-signup-active .supervisor-signup-container {
    display: none;
  }
}

button:disabled {
  cursor: not-allowed;
}

.loading:after {
  content: ' .';
  -webkit-animation: dots 1s steps(5, end) infinite;
          animation: dots 1s steps(5, end) infinite;
}

@-webkit-keyframes dots {
  0%,
  20% {
    color: rgba(0, 0, 0, 0);
    text-shadow: 0.25em 0 0 rgba(0, 0, 0, 0);
  }
  40% {
    color: white;
    text-shadow: 0.25em 0 0 rgba(0, 0, 0, 0);
  }
  60% {
    text-shadow: 0.25em 0 0 white;
  }
  80%,
  100% {
    text-shadow: 0.25em 0 0 white, 0.5em 0 0 white;
  }
}

@keyframes dots {
  0%,
  20% {
    color: rgba(0, 0, 0, 0);
    text-shadow: 0.25em 0 0 rgba(0, 0, 0, 0);
  }
  40% {
    color: white;
    text-shadow: 0.25em 0 0 rgba(0, 0, 0, 0);
  }
  60% {
    text-shadow: 0.25em 0 0 white;
  }
  80%,
  100% {
    text-shadow: 0.25em 0 0 white, 0.5em 0 0 white;
  }
}

:root {
  --border-color: teal;
}

.contact-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 500px;
  margin: auto;
  padding: 20px;
  height: 80vh;
  margin-bottom: 20px;
}

.contact-form {
  display: flex;
  flex-direction: column;
}

.header-text {
  letter-spacing: 6px;
  font-size: 30px;
}

.text-align-center {
  text-align: center;
}
.contact-container > form {
  display: flex;
  flex-direction: column;
  margin: 30px;
}

.contact-container > form > input,
.contact-container > form > textarea {
  padding: 8px ;
  font-size: 14px;
  font-weight: 300;
  color: rgb(24, 23, 23);
  letter-spacing: 1px;
  border: none;
  background: transparent;
  font-family: 'Mukta', sans-serif;
  width: 100%;
  outline: none;
  border: 1px solid teal;
  border: 1px solid var(--border-color);
  margin-top: 10px;
}

.send-button {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 8px;
  font-size: 14px;
  font-weight: 300;
  padding: 8px;
  border: 1px solid teal;
  border: 1px solid var(--border-color);
  color: teal;
  color: var(--border-color);
  cursor: pointer;
}

.send-button:hover {
  transform: translate3d(0px, -5px, 0px);
}
/*social media section */
.social-media-icon-container {
}

hr {
  color: teal;
  color: var(--border-color);
}

.social-media-icon-list {
  list-style-type: none;
  display: flex;
  justify-content: center;
  position: relative;
  font-size: 2rem;
  text-align: center;
  width: 100%;
}

.social-media-icon-list li a {
  color: white;
}

.social-media-icon-list li {
  position: relative;
  top: 0;
  /* left: -20px; */
  display: inline-block;
  height: 60px;
  width: 60px;
  margin: 8px auto;
  line-height: 60px;
  border-radius: 50%;
  color: #fff;
  background-color: teal;
  background-color: var(--border-color);
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

.social-media-icon-list li:hover {
  color: black;
}

.list-item {
  line-height: 4;
  color: rgb(112, 103, 103);
}

.Toastify button {
  display: none;
}

@media screen and (max-width: 768px)
{
  .contact{
    width: 100%;
  }
  .contact-container{
    margin-bottom: 60px;
  }
}



.landing_footer {
  width: 100%;
  height: 80px;
  background-color: black;
  font-size: 15px;
  color: rgb(131, 131, 131);
  bottom: 0;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 768px) {
  .landing_footer {
    font-size: 12px;
  }
}


.CourseHeader{
    height: 60px;
    border-bottom: 1px solid #e2e7ed;
    display: flex;
    align-items: center;
}

.LogoCourseSection{
    width: 34vmax;
    cursor: pointer;
}

.ProgressBar{
    width: 40vmax;
    cursor: pointer;
}
.Announcement{
    width: 15vmax;
    cursor: pointer;
}

.ProgressBarPercentile{
    margin-top: 5px;
    height: 7px; 
    width: 500px;
	background: #e2e7ed;
 border-radius: 13px;
    cursor: pointer;
    
}
.ProgressBarPercentile > span{
  display: block;
  height: 100%;
  border-radius: 13px;
  background-color: #31c984;
  overflow: hidden;
  cursor: pointer;
}

.ham{
    display: none;
    cursor: pointer;
}

.dropdown-content {
    margin-top: 10px;
    margin-left: -50px;
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
    cursor: pointer;
  }
  
  .dropdown-content a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
  }

  .UserDetails{
      cursor: pointer;
  }

  .UserDetails:hover .dropdown-content {
    display: block;
  }

@media screen and (max-width: 900px)
{
    .LogoCourseSection{
        width: 25vmax;
    }
    .ProgressBarPercentile{
        width: 250px;
    }
    .Announcement{
        width: 35vmax;
    }
    
}

@media screen and (max-width: 1100px)
{
    .LogoCourseSection{
        width: 25vmax;
    }
    .ProgressBarPercentile{
        width: 250px;
    }
    .ProgressBar{
        width: 60vmax;
    }
    .Announcement{
        width: 30vmax;
    }
    
}

@media screen and (max-width: 1260px)
{
    .LogoCourseSection{
        width: 25vmax;
    }
    .ProgressBarPercentile{
        width: 300px;
    }
    .ProgressBar{
        width: 60vmax;
    }
    .Announcement{
        width: 30vmax;
    }
    
}



@media screen and (max-width: 800px) {
    .ProgressBarPercentile{
        width: 20vmax;
    }
    .LogoCourseSection{
        display: none;
    }
    .ans{
        display: none;
    }
    .ham{
        display: block;
        margin-left: 10px;
    }
    .ProgressLabel{
        text-align: center;
    }
    .ProgressBar{
        width: 20vmax;
        margin-right: auto;
        order: 0;
        margin-left: 40px;
    }
    .UserDetails{
        margin-left: auto;
        order: 1;
        margin-right: 5px;


    }
    
    
  }




.Sidebar_Container{
    width: 350px;
    position: fixed;
    border: 1px solid #e2e7ed;
    border-top: none;
    height: 95vh;
    background-color: 'white';
    

    

}
.CourseContents{
    height: 100%;
    overflow-y: auto;
    transition: 0.3s ease;
}

.CourseHeading{
     height: 75px ;
     background-color:#102040;
     display: flex ;
     align-items: center;
}

.coursetitle{
    /* font-size: 18px; */
    /* font-weight: bold ;  */
    margin-left: 25px ; 
    padding-right: 65px;

}
#leftArrow:focus{
    outline: none;

}


@media screen and (max-width: 600px) {
    /* #coursetitle{
        font-size: 0px !important;
        display: none !important;
        width: 0px !important;
    } */
  }



.unit{
    background-color: #ebebeb;
    height: 55px;
    display: flex;
    align-items: center;
    cursor: pointer;
}
.CourseContent{
    background-color: white;
    height: 55px;
    border: .5px solid #e2e7ed;
    display: flex;
    align-items: center;
    cursor: pointer;
    
}
.VideoContainer {
  width: 100%;
  height: 85vh;
}

.quiz-container {
  margin-top: 40px;
  margin-left: 34%;
  font-size: 15px;
  font-weight: bold;
  color: #0033ff;
  padding-bottom: 5px;
  width: 43%;
  width: 810px;
  height: 500px;
}

#video {
  margin: 0 auto;
  padding-top: 20px;
  width: 810px !important;
  height: 510px !important;
  margin-left: 34%;
  padding-bottom: 50px;
}
.unitinfo {
  margin-top: 40px;
  margin-left: 34%;
  font-size: 15px;
  font-weight: bold;
  color: #575d76;
  padding-bottom: 5px;
}

.LectureTitle {
  font-size: 13px;
  margin-left: 34%;
  background-color: #f0f3f6;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  display: flex;
  align-items: center;
  padding: 5px;
  width: 810px;
  border-radius: 5px;
}

.forwardbackward {
  margin-left: 34%;
  width: 810px;
  display: flex;
  align-items: center;
}
#leftarrow,
#rightarrow {
  position: relative;
}

.btn1:hover #leftarrow {
  right: 5px;
  transition: ease all 0.3s;
}
.btn3:hover #rightarrow {
  left: 5px;
  transition: ease all 0.3s;
}

.assignmentdetails {
  margin-left: 34%;
  background-color: #f0f3f6;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  display: flex;
  align-items: center;
  padding: 10px;
  width: 810px;
  border-radius: 5px;
}

.FileUploader {
  padding-top: 20px;
  padding-bottom: 50px;
  margin-left: 34%;
}

.filechooser {
  padding-top: 50px;
  padding-bottom: 50px;
  border: 1px dashed gray;
  width: 810px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
#upload {
  width: 810px;
  height: 30px;
  background-color: #4faf3d;
  border-style: none;
  border-radius: 5px;
  cursor: pointer;
}
#upload:focus {
  outline: none;
}

#uploadprogressbar {
  margin-top: 10px;
  margin-bottom: 10px;
  width: 810px;
}

@media screen and (max-width: 1200px) {
  #video {
    margin: 0 auto;
    padding-top: 50px;
    width: 59% !important;
    height: 450px !important;
    margin-left: 40%;
    padding-bottom: 50px;
  }
  .unitinfo,
  .quiz-container {
    width: 59% !important;
    margin: 0 auto;
    padding-top: 10px;
    margin-left: 40%;
  }
  .LectureTitle {
    margin-left: 40%;
    background-color: #f0f3f6;
    height: 43px;
    display: flex;
    align-items: center;
    padding: 5px;
    width: 59% !important;
    border-radius: 5px;
    text-align: center;
  }
  .forwardbackward {
    width: 59% !important;
    margin-left: 40%;
  }

  .assignmentdetails {
    margin-left: 40%;
    width: 59% !important;
  }
  .FileUploader {
    margin-left: 40%;
    width: 59% !important;
  }
  .filechooser {
    width: 100% !important;
  }
  #upload {
    width: 100% !important;
  }
  #uploadprogressbar {
    width: 100%;
  }
}

@media screen and (max-width: 800px) {
  #video {
    margin: 0 auto;
    padding-top: 20px;
    width: 85% !important;
    height: 500px !important;
    padding-bottom: 50px;
  }
  .unitinfo,
  .quiz-container {
    width: 85% !important;
    margin: 0 auto;
    padding-top: 10px;
  }
  .LectureTitle {
    margin: 0 auto;
    background-color: #f0f3f6;
    height: 43px;
    display: flex;
    align-items: center;
    padding: 5px;
    width: 85% !important;
    border-radius: 5px;
    text-align: center;
  }
  .forwardbackward {
    margin: 0 auto;
    width: 85% !important;
  }

  .assignmentdetails {
    width: 85% !important;
  }
  .FileUploader {
    width: 85% !important;
  }
  .filechooser {
    width: 100% !important;
  }
  #upload {
    width: 100% !important;
  }
  #uploadprogressbar {
    width: 100%;
  }
}

@media screen and (max-width: 760px) {
  /* .Sidebar_Container{
        width: 0px !important;
        color: transparent !important;
    }
    #coursetitle{
        color: transparent !important;
    } */

  .CourseSection {
    overflow-y: auto;
  }

  #video {
    margin: 0 auto;
    width: 89% !important;
    height: 45% !important;
  }
  .unitinfo {
    width: 90% !important;
    margin: 0 auto;
    padding-top: 10px;
  }
  .LectureTitle {
    width: 90% !important;
    height: 90px;
    margin: 0 auto;
    flex-direction: column;
    align-items: center;
    text-align: center;
    justify-content: space-evenly;
  }
  .forwardbackward {
    width: 90% !important;
    display: flex;
    flex-direction: column;
    margin: auto !important;
  }
  .btn1 {
    font-size: 14px;
    margin-left: auto !important;
    margin-bottom: 5px;
  }
  .btn2 {
    font-size: 14px;
  }
  .btn3 {
    font-size: 13px;
    margin-right: auto !important;
    margin-top: 5px;
    margin-bottom: 80px;
  }
  .inprogress {
    padding-top: 3px;
    margin: 0 auto !important;
  }

  .assignmentdetails {
    margin: 0 auto;
    width: 88% !important;
  }
  .FileUploader {
    margin: 0 auto;
    width: 90% !important;
  }
  #uploadprogressbar {
    width: 100%;
  }
}

.question-answer-container {
  display: grid;
  justify-content: center;
  align-content: center;
  border-radius: 1px;
  background-image: radial-gradient(whitesmoke, whitesmoke);
  font-size: 1rem;
  padding: 2px;
  padding-bottom: 5px;
  margin-bottom: 10px;
}

.submit-btn-quiz {
  background-color: black;
  color: white;
  padding: 5px;
  border-radius: 55px;
  text-transform: capitalize;
}

.question {
  display: inline-block;
  background: rgb(58, 57, 57);
  color: white;
  padding: 15px;
}

.question-number {
  display: inline-block;
  height: 20px;
  width: 20px;
  background: white;
  color: black;
  border-radius: 100%;
  margin: 0 5px 0 0;
  text-align: center;
}

.answer-container {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-template-rows: repeat(4, 1fr);
  grid-row-gap: 20px;
  row-gap: 20px;
  /* grid-column-gap: 50px; */
  margin: 10px 20px;
}

.answer {
  display: inline-block;
  font-size: 20px;
  border-radius: 5px;
  text-align: start;
  padding: 10px;
  color: rgb(255, 255, 255);
  background-color: rgb(3, 67, 163);
}

.black-border {
  border: 5px solid rgb(0, 0, 0);
}

.red-border {
  border: 5px solid rgb(211, 0, 0);
}

.green-border {
  border: 5px solid rgb(64, 216, 64);
}

.next-btn {
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  justify-self: end;
  margin: 10px;
  padding: 10px;
  background: rgb(58, 57, 57);
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.hidden {
  display: none;
}

@media screen and (max-width: 768px) {
  .question-answer-container {
    margin: 0 auto;
    font-size: .8rem;
  }
  .answer{
    font-size: 13px !important;
  }
}

/* .CourseSection{
    background-color: #318CE7;
} */
.admin-nav-bar{
    background-color: white;
    height: 60px;
    width: 100%;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
    display: flex;
    align-items: center;
    padding-left: 20px;
    justify-content: flex-end;
    grid-gap: 30px;
    padding-right: 20px;
    /* position: fixed; */
}

.admin-menu-icon{
  display: none;   
}

.avatar{
  cursor: pointer;
  display: block;
}

.dropdown-content1 {
  margin-top: 0px;
  margin-left: -100px;
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 100;
  cursor: pointer;
}

.dropdown-content1 a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}



.avatar:hover .dropdown-content1{
  display: block!important;
}


@media screen and (max-width:768px)
{
    .admin-menu-icon{
        display: inline-block;   
      }
    .admin-nav-bar{
      position: fixed;
    }
}
.admin-sidebar {
  height: 100vh;
  background-color: rgb(31, 30, 30);
  width: 280px;
  /* position: fixed; */

}

.admin-logo{
    font-weight: 900;
    color: white;
    text-align: center;
    font-size: 24px;
    padding-top: 20px;
    height: 60px;
    
}

.functionalities{
    color: white;
}

.admin-menues{
    display: flex;
    flex-direction: column;
    justify-content: left;

}


.admin-menues>li:hover{
    background-color: blue;
    cursor: pointer;
}

.admin-menues>li a{
    color: white;
    text-decoration: none;
    display:inline-block;
    width:100%;
    padding: 20px;
 
}


@media screen and (max-width:768px)
{
   .admin-sidebar{
       display: none;
   }
   .admin-menues>li a{
    padding: 10px;
}

}

@media screen and (max-width:1020px)
{
   .admin-menues>li a{
    padding: 10px;
}

}


.cards{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    overflow: hidden;
}

.dashboardContent{
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    margin:20px;
    margin-top: 0px;
}


@media screen and (max-width: 768px)
{
    .cards{
        margin-left: -20px;
       justify-content: center;
       flex-wrap: wrap;
       
    }
    
}


.CardView{
    width: 260px;
    height: 100px;
    background-color: #00c0ef;
    color: white;
    align-items: center;
    display: flex;
    justify-content: center;
    font-size: 14px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
    margin: 20px;
}


.TableView {
  margin: 5px;
  width: 100%;
  height: 68vh;
  overflow-x: auto;
  overflow-y: auto;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
}
table {
  width: 100%;
}
thead{
    background-color: blueviolet;
    color: white;
}
tbody{
    padding-top: 50px;
}
td,
th {
  padding: 10px;
  border-bottom: 1px solid black;
  text-align: left;
  font-size: 14px;
}
.size {
  width: 50px !important;
  height: 50px !important;
  font-weight: bold;
}
@media screen and (max-width: 768px) {
  td,
  th {
    padding: 5px;
    font-size: 8px;
  }
  .TableView{
    margin: 0px;
    margin-left: -12px;
    
  }
}


.review_assignment{
    margin: 20px;
    margin-top: 10px;
}
input[type='text'] {
    margin: 10px 10px 10px 0px;
    padding: 7px;
    width: 100%;
  }
textarea{
    margin: 10px 10px 10px 0px;
    width: 100%;
    padding: 5px;
}
input[type="submit"]
{
    margin: 10px 10px 10px 0px;
    padding: 8px;
    width: 300px;
    color: white;
    background-color: blueviolet;
    border-style: none;
    box-shadow: 0 2px 4px 0 rgba(0.2, 0.2, 0.2, 0.5);
    border-radius: 5px;
}

.pdf-viewer
{
    height: 350px;
}

.assignment-name{
    display: inline;
    text-align: center;

}

@media screen and (max-width: 768px)
{
    .pdf-viewer{
        height: 300px;
    }
    .assignment-name{
        font-size: 13px;
    }
}



.create-blog
{
    margin: 20px;
    margin-top: 10px;
    
}
.blog_write{
    border: 1px solid black;
    height: 400px;
    padding: 5px;   
    overflow-y: auto;
}

.post-button{
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 10px 10px 10px 0px;
    padding: 8px;
    width: 300px;
    color: white;
    background-color: blueviolet;
    border-style: none;
    box-shadow: 0 2px 4px 0 rgba(0.2, 0.2, 0.2, 0.5);
    border-radius: 5px;
    outline-style: none;
    font-weight: bold;
}

select{
    margin: 10px 10px 10px 0px;
    padding: 7px;
    width: 100%;
}

input[type='text'] {
    margin: 10px 10px 10px 0px;
    padding: 7px;
    width: 100%;
  }
textarea{
    margin: 10px 10px 10px 0px;
    width: 100%;
    padding: 5px;
}



.heading_name
{
    background-color: blueviolet;
    color: white;
    width: 100%;
    border-radius: 5px;
    padding: 6px;
    font-size: 18px;
}


.create-course{

    height: 500px;
    display: flex;
    flex-direction: column;
    margin: 20px;
    margin-top: 10px;

}

.heading_name
{
    background-color: blueviolet;
    color: white;
    width: 100%;
    border-radius: 5px;
    padding: 6px;
    font-size: 18px;
}

input[type='text'] {
    margin: 10px 10px 10px 0px;
    padding: 7px;
    width: 100%;
  }
textarea{
    margin: 10px 10px 10px 0px;
    width: 100%;
    padding: 5px;
}
input[type="submit"]
{
    margin: 10px 10px 10px 0px;
    padding: 8px;
    width: 300px;
    color: white;
    background-color: blueviolet;
    border-style: none;
    box-shadow: 0 2px 4px 0 rgba(0.2, 0.2, 0.2, 0.5);
    border-radius: 5px;
}

.course-curriculumn{
    margin-top: 10px;
    border: 1px solid black;
    width: 100%;
    height: 380px;
    overflow-y: scroll;
}
.unit-section{
    margin: 8px;
    background-color: #e6e8eb;
    padding: 8px;
    box-shadow: 0 2px 4px 0 rgba(0.2, 0.2, 0.2, 0.5);
}

.sections{
    margin: 8px;
    background-color: white;
    padding: 8px;
}

.questions{
    margin: 8px;
    background-color: rgb(48, 107, 185);
    padding: 8px;
    color: white;
}
.create-unit-button{
    background-color: rgb(48, 185, 117);
    color: white;
    border-style: none;
    padding: 8px;
    margin: 8px;
    font-weight: bold;

}

.create-course-button{
    font-weight: bold;
    
}


.create-events{
    margin: 20px;
    margin-top:10px;
    
}

.post-button{
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 10px 10px 10px 0px;
    padding: 8px;
    width: 300px;
    color: white;
    background-color: blueviolet;
    border-style: none;
    box-shadow: 0 2px 4px 0 rgba(0.2, 0.2, 0.2, 0.5);
    border-radius: 5px;
    outline-style: none;
    font-weight: bold;
}

input[type='date'] {
    margin: 10px 10px 10px 0px;
    padding: 7px;
    width: 100%;
  }

  input[type='time'] {
    margin: 10px 10px 10px 0px;
    padding: 7px;
    width: 100%;
  }

  .event-body{
      height: 300px;
      padding: 5px;
      border: 1px solid black;
      overflow-y: auto;
  }

  input[type='text'] {
    margin: 10px 10px 10px 0px;
    padding: 7px;
    width: 100%;
  }
textarea{
    margin: 10px 10px 10px 0px;
    width: 100%;
    padding: 5px;
}

.heading_name
{
    background-color: blueviolet;
    color: white;
    width: 100%;
    border-radius: 5px;
    padding: 6px;
    font-size: 18px;
}




.create-news{
    margin: 20px;
    margin-top: 10px;
}

.post-button{
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 10px 10px 10px 0px;
    padding: 8px;
    width: 300px;
    color: white;
    background-color: blueviolet;
    border-style: none;
    box-shadow: 0 2px 4px 0 rgba(0.2, 0.2, 0.2, 0.5);
    border-radius: 5px;
    outline-style: none;
    font-weight: bold;
}

.news-details{
    border: 1px solid black;
    height: 400px;
    padding: 5px;   
    overflow-y: auto;
}


select{
    margin: 10px 10px 10px 0px;
    padding: 7px;
    width: 100%;
}

input[type='text'] {
    margin: 10px 10px 10px 0px;
    padding: 7px;
    width: 100%;
  }
textarea{
    margin: 10px 10px 10px 0px;
    width: 100%;
    padding: 5px;
}
input[type="submit"]
{
    margin: 10px 10px 10px 0px;
    padding: 8px;
    width: 300px;
    color: white;
    background-color: blueviolet;
    border-style: none;
    box-shadow: 0 2px 4px 0 rgba(0.2, 0.2, 0.2, 0.5);
    border-radius: 5px;
}


.heading_name
{
    background-color: blueviolet;
    color: white;
    width: 100%;
    border-radius: 5px;
    padding: 6px;
    font-size: 18px;
}




.functionalities1{
    background-color: rgb(31, 30, 30);
    color: white;
    margin-top: -18px;
    padding: 20px;
    position: fixed;
    top:80px;
    z-index: 10000 !important;
}

.functionalities1.active{
    display: none;
}

.admin-menues{
    display: flex;
    flex-direction: column;
    justify-content: left;


}


.admin-menues>li:hover{
    background-color: blue;
    cursor: pointer;
}

.admin-menues>li a{
    color: white;
    text-decoration: none;
    display:inline-block;
    width:100%;
    padding: 20px;
 
}



.admin-mainwindow{
    margin-top: 60px;
    height: 80vh;
    width: 86%;
    background-color: white;
    float: right;

}

.admin-nav-bar{
    background-color: white;
    height: 60px;
    width: 100%;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
    display: flex;
    align-items: center;
    padding-left: 20px;
    justify-content: flex-end;
    grid-gap: 30px;
    padding-right: 20px;
    /* position: fixed; */
}

.admin-menu-icon{
  display: none;   
}

.avatar{
  cursor: pointer;
  display: block;
}

.dropdown-content1 {
  margin-top: 0px;
  margin-left: -100px;
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 100;
  cursor: pointer;
}

.dropdown-content1 a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}



.avatar:hover .dropdown-content1{
  display: block!important;
}



@media screen and (max-width:1020px)
{
    .admin-mainwindow{
        width: 74%;
    }
}
@media screen and (max-width:768px)
{
    .admin-mainwindow{
        width: 100%;
        margin-top: 40px;
    }
    .admin-menu-icon{
        display: inline-block;   
      }
    .admin-nav-bar{
      position: fixed;
    }
    
}
*{
    box-sizing: border-box;
}
.DashboardHeader{
    height: 80px;
    border-bottom: 1px solid #e2e7ed;
    display: flex;
    align-items: center;
    justify-content: space-around;
    box-shadow: 0 2px 2px 0 rgba(184, 181, 181, 0.2);
    
    
}

/* .DashboardUserDetails{
    margin-left:  auto;
    margin-right: 10px;
} */

.courseContainer{
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: Arial, Helvetica, sans-serif;
    height: 90vh;
    margin: 0px;
    margin-top: -40px;
}

.course{
    background: #FFF;
    border-radius: 4px;
    box-shadow: 0px 5px 40px rgba(0,0,0,0.2);
    display: inline-block;
    overflow: hidden;
}

.course-info{
    background:#2C2464;
    color: #FFF;
    float: left;
    padding: 30px;
    width:40%;
}

.course-progress{
    float: left;
    padding: 30px;
    position: relative;
    width: 60%;
}

.course h2{
    letter-spacing: 1px;
    margin: 10px 0px;
}

.course h6{
    letter-spacing: 1px;
    opacity: 0.8;
    text-transform: uppercase;
    margin: 0px;
}

.course-info a{
    color: #FFF;
    display: inline-block;
    font-size: 12px;
    opacity: 0.8;
    margin-top: 30px;
    text-decoration: none;
}

.progress-container{
    position: absolute;
    top: 30px;
    right: 30px;
    text-align: right;
    width: 150px;
}

.progress{
    background: #DDD;
    border-radius: 3px;
    height: 5px;
    width: 100%;
}

.progress >span{
    border-radius: 3px;
    background: #2C2464;
    content: '';
    position: absolute;
    top: 0px;
    left: 0px;
    height: 5px;
}

.progress-text{
    padding-top: 10px;
    font-size: 10px;
    opacity: 0.8;
    letter-spacing: 1px;
}

.btn{
    background: #2C2464;
    border: 0px;
    outline: none;
    border-radius: 2px;
    /* box-shadow: 0px 10px 10px rgba(0,0,0,0.2); */
    color: #FFF;
    font-size: 16px;
    padding: 12px 25px;
    position: absolute;
    right: 30px;
    bottom: -28px !important;
    cursor: pointer;
    letter-spacing: 1px;
}

.dropdown-content {
    margin-top: 10px;
    margin-left: -50px;
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
    cursor: pointer;
  }
  
  .dropdown-content a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
  }

  .DashboardUserDetails{
      cursor: pointer;
      margin-top: 2px;
  }

  .DashboardUserDetails:hover .dropdown-content {
    display: block;
  }


@media screen and (max-width: 768px)
{

    .headerText{
        display: none !important;
    }
    .courseContainer{
        margin-left: 10px;
        margin-right: 10px;
    }
    .course-progress, .course-info{
        width: 100%;
        height: 200px;
        float: none;
    }
    .course-info{
        height: 140px;
    }
    .course-info h2{
        margin: 8px 0px;
    }
    .course-info a{
        margin-top: 10px;
    }
    .course-progress h2{
        margin: 16px 0px;
    }
    .btn{
        bottom: 10px !important;
        left: 30px;
    }
}
.singup-logo {
  /* width: 200px; */
  height: 100px;
  /* background-color: rgb(0, 238, 127); */
  margin: 20px;
}

.singup-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 500px;
  margin: auto;
  padding: 20px;
}

form {
  width: 100%;
}

.singup-container > form {
  display: flex;
  flex-direction: column;
  margin: 50px;
}

.singup-container > form > input {
  padding: 10px 0 10px 15px;
  font-size: 17px;
  font-weight: 300;
  color: rgb(24, 23, 23);
  letter-spacing: 1px;
  border: none;
  background: transparent;
  font-family: 'Mukta', sans-serif;
  width: 100%;
  outline: none;
  border: 1px solid rgb(216, 60, 255);
  margin-top: 10px;
}

.submit-btn {
  padding: 10px 0 10px 15px;
  font-size: 17px;
  font-weight: 300;
  color: rgb(216, 60, 255);
  letter-spacing: 1px;
  border: none;
  background: transparent;
  font-family: 'Mukta', sans-serif;
  width: 100%;
  outline: none;
  border: 1px solid rgb(216, 60, 255);
  margin-top: 10px;
  cursor: pointer;
}

.submit-btn:hover {
  background: whitesmoke;
}

.error {
  color: red;
}

#research-field, #occupation{
  padding: 10px 0 10px 15px;
  font-size: 17px;
  font-weight: 300;
  color: rgb(24, 23, 23);
  letter-spacing: 1px;
  border: none;
  background: transparent;
  font-family: 'Mukta', sans-serif;
  width: 100%;
  outline: none;
  border: 1px solid rgb(216, 60, 255);
  margin-top: 10px;
  cursor: pointer;
}

.login-logo {
  width: 200px;
  height: 200px;
  /* background-color: rgb(0, 238, 127); */
  margin: 20px;
}

.login-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 500px;
  margin: auto;
  padding: 20px;
}

form {
  width: 100%;
}

.login-container > form {
  display: flex;
  flex-direction: column;
  margin: 50px;
}

.login-container > form > input {
  padding: 10px 0 10px 15px;
  font-size: 17px;
  font-weight: 300;
  color: rgb(24, 23, 23);
  letter-spacing: 1px;
  border: none;
  background: transparent;
  font-family: 'Mukta', sans-serif;
  width: 100%;
  outline: none;
  border: 1px solid rgb(216, 60, 255);
  margin-top: 10px;
}

.submit-btn {
  padding: 10px 0 10px 15px;
  font-size: 17px;
  font-weight: 300;
  color: rgb(216, 60, 255);
  letter-spacing: 1px;
  border: none;
  background: transparent;
  font-family: 'Mukta', sans-serif;
  width: 100%;
  outline: none;
  border: 1px solid rgb(216, 60, 255);
  margin-top: 10px;
  cursor: pointer;
}

.submit-btn:hover {
  background: whitesmoke;
}

.error {
  color: red;
}

