.width-seventy {
  width: 70%;
}
.center {
  margin: 20px auto;
}

.text-container {
  color: #333333;
  font-family: Tahoma, Geneva, Verdana, sans-serif;
  font-weight: 400;
  font-size: 16px;
  margin: 10px 0;
}

ul {
  list-style-type: none;
}

.text-align-center {
  text-align: center;
  color: #333333;
  font-size: 18px;
  text-align: left;
  font-weight: 600;
}

.heading{
  color: #333333;
  font-size: 20px;
}

@media screen and (max-width: 768px) {
  .width-seventy {
    width: 100%;
  }
  .text-container {
    padding-left: 5px;
    font-weight: 300;
  }
  .text-align-center {
    padding-left: 5px;
    font-weight: 400;
  }
}
